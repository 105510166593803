<template>
    <div class="supplierShipment-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
        <el-dialog title="选择文件" :visible.sync="dialogVisibleUpload" @close="closeFun()" :close-on-click-modal="false">
            <div class="up_add_bg" @click='onImgFunBtn'>
            选择文件
            <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
            </div>
            <div class="upload_fileName">文件名称：{{fileName}}</div>
            <!-- .xls .xslx 格式 -->
            <div class="dialog_bottom">
                <el-button type="primary" :disabled="sureBtnFlag" @click="commitFile">确认</el-button>
                <el-button type="primary" @click="closeFun">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    import axios from "axios";
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                fileName: '',
                formData: new FormData(),
                file: {}, //文件数据  
                dialogVisibleUpload: false,
                sureBtnFlag: false,
                opt: {
                    title: '出货记录',
                    search: [{
                            key: "inMerchantId",
                            label: "进货商名称",
                            type: 'remoteSearchById',
                            opt: {
                                list: [],
                                change(data,cb) {
                                    _this.opt.search.forEach(item => {
                                        if(item.key == 'category') {
                                        cb({
                                            value: "",
                                            key: item.key,
                                        })
                                        item.opt.list = [];
                                        }
                                    })
                                    _this.getCategory(data); 
                                }
                            }
                        }, {
                            key: "category",
                            label: "商品品类",
                            type: "selectCity",
                            opt: {
                                list: [],
                                parent: "inMerchantId",
                            }
                        },
                        {
                            key: "name",
                            label: "商品名称",
                            maxlength: 32,
                            rules: [{
                                validator: validItemsName,
                                trigger: ['blur']
                            }]
                        },
                        {
                            key: "code",
                            label: "商品编号",
                            rules: [{
                                validator: validCode,
                                trigger: ['blur']
                            }]
                        },
                        
                        {
                            key: "inNumber",
                            label: "商家入库单号",
                        },
                        {
                            key: "date",
                            label: "出货时间",
                            type: 'data-range',
                        }
                    ],
                    columns: [{
                            label: "商品名称",
                            key: "goodsName"
                        },
                        {
                            label: "商品编号",
                            key: "goodsCode"
                        },
                        {
                            label: "商品品类",
                            key: "goodsCategory"
                        },
                        {
                            label: "成本价（元/件）",
                            key: "goodsCost"
                        },
                        {
                            label: "进货商名称",
                            key: "merchantName"
                        }, 
                        {
                            label: "商家入库单号",
                            key: "inNumber"
                        },
                        {
                            label: "出货商品数（件）",
                            key: "inCount"
                        },
                        {
                            label: "出货时间",
                            key: "createDate"
                        },
                        
                    ],
                    buttons:[],
                    exportButtons:[{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/warehouse/shipment/export",
                        listName: '出货记录'
                    }],
                },
                categoryList: [],
            };
        },
        created() {
            console.log("bePutInStorage created!!");
        },
        activated() {
            console.log("bePutInStorage activated!!");
            this.getMerchantList()
        },
        deactivated() {
            // 离开页面，重置商品品类的list,避免选择了一个商家之后，离开本页面在不刷新浏览器的情况下再次进入本页面，商品品类显示上个商家数据的问题
            this.opt.search.forEach(item => {
                if(item.key == 'category') {
                    item.opt.list = [];
                }
            })
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    current: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm,
                    inStartDate: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
                    inEndDate: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
                };
                this.post("mall-service/v1/warehouse/query/in/list", dto, {
                    isUseResponse: true
                }).then(res => {
                    let obj = {
                        data: {
                            data: [],
                            total: 0,
                        }
                    }
                    if(res.data.code == 0) {
                        if(res.data.data.data) {
                            res.data.data.data.forEach(item => {
                                item.merchantName = item.distributorId ? (item.distributorName||'--') : (item.merchantName || '--');
                            })
                        }
                    }
                    obj.data.total = res.data.data.total;
                    obj.data.data = res.data.data.data;
                    
                    opt.cb(obj.data);
                });
            },
            getCategory(data) {
                if(!data) {
                    return
                }
                let dto = {
                    pageNum: 1,
                    pageSize: 9999,
                    merchantId: data
                };
                this.post("mall-service/category/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    var array = res.data.list;
                    array.forEach(item => {
                        item.value = item.id;
                        item.label = item.name;
                    })
                    this.categoryList = array;
                    this.opt.search.forEach(item => {
                        if (item.key == "category") {
                            item.opt.list = array;
                        }
                    })

                })
            },
            // 获取商家列表
            getMerchantList() {
                this.post('/mall-service/merchant/v1/page', {
                    pageNumber: 1,
                    pageSize: 9999,
                    roleTypeList: [1,3]
                }).then(res => {
                    var array = res.data;
                    array.forEach(item => {
                        item.value = item.id;
                        item.label = item.name;
                    })
                    console.log(array);
                    this.opt.search.forEach(item => {    
                        if(item.key == 'inMerchantId') {
                        item.opt.list = array
                        }
                    })
                })
            },
            onImgFunBtn() {
                document.getElementById('fileupload').click();
            },
            //选择文件
            addFile: function (e) {
                var _this = this;
                let inputDOM = this.$refs.inputer;
                this.file = inputDOM.files[0];
                let len = this.file.length;
                let size = Math.floor(this.file.size / 1024);
                if (size > 50 * 1024 * 1024) {
                alert("请选择50M以内的文件！");
                return false;
                }
                this.fileName = document.getElementById('fileupload').files[0].name;
                this.formData.append("file", this.file);
                this.sureBtnFlag = false;
                e.target.value = '';
            },
            commitFile(){
                var _this = this;
                if (!this.fileName) {
                    return this.$message({
                        message: "请选择文件",
                        type: "warning"
                        });
                }
                this.sureBtnFlag = true;
                axios({
                    method: "POST",
                    // url: this.BASE_URL + "/downloadFile/add",
                    url: _this.UPLOAD_URL + "/mall-service/v1/warehouse/inventoryIn/import",
                    data: this.formData,
                    headers: {
                    accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
                    securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem("securityKey"),
                    'Content-Type': 'multipart/form-data',
                    },
                })
                .then((response) => {
                    this.formData = new FormData();
                    if (response.data.code == 0) {
                        this.$message({
                            type: 'success',
                            message: '导入成功!'
                        })
                        this.sureBtnFlag = false;
                        this.dialogVisibleUpload = false;
                        this.$refs.myList.get();
                    } else {
                        this.$message({
                            type: 'warning',
                            message: response.data.msg
                        })
                        this.sureBtnFlag = true;
                    }
                })
                .catch((error) => {
                    this.sureBtnFlag = false;
                    this.formData = new FormData();
                });
            },
            closeFun() {
                this.sureBtnFlag = false;
                this.dialogVisibleUpload = false;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .dialog_bottom {
        margin-top: 23px;
        width: 100%;
        text-align: center;

        .el-button {
        width: 80px !important;
        height: 36px !important;
        padding-top: 10px;
        }
    }

    .up_add_bg {
        width: 95px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: #FFFFFF;
        border: 1px solid #CFCFD7;
        border-radius: 6px;
        cursor: pointer;

        .upload-btn {
        opacity: 0;
        }
    }

    .upload_fileName {
        padding-top: 10px;
        padding-right: 5px;
    }
</style>